import { useState, useEffect } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import { Facebook, Linkedin, Twitter } from 'react-feather';
import { midBreakPoint } from './breakpoints';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const Footer = () => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <footer className='container-flex text-light'>
            <div className='container sticky-bottom py-4 bg-transparent'>
                <div className='row'>
                    <div className='col-2 d-none d-md-block'>
                        <h5><b>About Us</b></h5>
                        <ul className='nav flex-column'>
                        <li className='nav-item mb-2'><Link to='/' className='nav-link p-0 text-light'>Home</Link></li>
                        <li className='nav-item mb-2'><Link to='/privacy' className='nav-link p-0 text-light'>Privacy Policy</Link></li>
                        <li className='nav-item mb-2'><Link to='/tc' className='nav-link p-0 text-light'>Terms & Conditions</Link></li>
                        </ul>
                    </div>

                    <div className='col-2 d-none d-md-block'>
                        <h5><b>Help</b></h5>
                        <ul className='nav flex-column'>
                        <li className='nav-item mb-2'><Link to='/deleteAccount' className='nav-link p-0 text-light'>Account Deletion</Link></li>
                        </ul>
                    </div>

                    <div className='col-4 offset-3 d-none d-md-block'>
                        <form className='form-group pb-5'>
                            <h5><b>Subscribe to our newsletter</b></h5>
                            <p>Monthly digest of whats new and exciting from us.</p>
                            <div className='d-flex w-100 gap-2'>
                                <label htmlFor='newsletter1' className='visually-hidden'>Email address</label>
                                <input id='newsletter' type='text' className='form-control' placeholder='Email address' />
                                <button className='btn btn-primary' type='button' style={{ backgroundColor: '#2D9797FF' }}>Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={windowDimensions.width >= midBreakPoint ? 'justify-content-between py-3 my-3 border-top d-flex' : 'justify-content-between d-flex'}>
                    <p className='float-left'>© 2024 Birbli LLC.</p>
                    <ul className='list-unstyled d-flex float-right'>
                        <li className='ms-3'><a href='#'><Facebook color='white'/></a></li>
                        <li className='ms-3'><a href='#'><Twitter color='white' /></a></li>
                        <li className='ms-3'><a href='https://www.linkedin.com/company/birbli/'><Linkedin color='white' /></a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};
