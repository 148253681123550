import { Link } from 'react-router-dom';
import logo from '../assets/birbli_logo.png';
import './Navbar.scss';
import { useEffect, useState } from 'react';
import { Offcanvas, Navbar as NAV, NavDropdown, Nav } from 'react-bootstrap';

export const Navbar = () => {

    const offsetPosition = 20;

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    
    return (
        <NAV expand='md' className='navbar fixed-top bg-white' style={offset > offsetPosition ? {borderBottom: '1px solid #c6c6c6'} : {borderBottom: 'none'}}>
          <div className='container bg-transparent'>
            <Link to='/' className='navbar-brand'>
                <img src={logo} alt='birbli logo' width={60} height={60} />
                <span className='fw-bold'>BIRBLI</span>
            </Link>
            <NAV.Toggle aria-controls='offcanvasNavbar' className='border-0' />
            <NAV.Offcanvas
              id='offcanvasNavbar'
              aria-labelledby='offcanvasNavbarLabel'
              placement='end'
            >
              <Offcanvas.Header closeButton />
              <Offcanvas.Body>
                <Nav className='justify-content-end flex-grow-1 d-sm-block d-md-none ms-3'>
                  <Link to='/' className='fs-2 text-decoration-none fw-bold text-dark'>Home</Link>
                  <NavDropdown
                    title='About Us'
                    className='fs-2 fw-bold d-block'
                  >
                    <p><Link to='/privacy' className='fs-5 text-decoration-none text-dark ms-2'>Privacy Policy</Link></p>
                    <p><Link to='/tc' className='fs-5 text-decoration-none text-dark ms-2'>Terms & Conditions</Link></p>
                  </NavDropdown>
                  <NavDropdown
                    title='Help'
                    className='fs-2 fw-bold'
                  >
                    <p><Link to='/deleteAccount' className='fs-5 text-decoration-none text-dark ms-2'>Account Deletion</Link></p>
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </NAV.Offcanvas>
          </div>
        </NAV>
    );
};
